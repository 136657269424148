<template>

  <v-container
    fluid
    tag="section">


<v-dialog  v-model="alert_msg"  width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text>
            {{msg_dialog}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="alert_msg = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>

  <base-material-card
      icon="mdi-basket"
      title="Loja"
      class="px-5 py-3">
<v-form ref="form">
 
</v-form>


  <v-row>
  <v-col
        cols="12"
        sm="6"
        lg="4"
      >

        

 </v-col>  
  <v-col
        cols="12"
        sm="6"
        lg="4"
      >
      <v-card
            color="#9e9e9e"
            dark
          >
            <v-card-title class="text-h3">
                   <v-icon>mdi-alarm</v-icon>  &nbsp; Solicitado Pedente
            </v-card-title>
            <br/>
           <v-card-title > <h1>{{this.solicitado}}</h1></v-card-title>

          </v-card>

 </v-col> 
    <v-col
        cols="12"
        sm="6"
        lg="4"
      >

         <v-card
            color="#9e9e9e"
            dark
          >
            <v-card-title class="text-h3">
                   <v-icon>mdi-trophy</v-icon>  &nbsp; Pontos
            </v-card-title>
            <br/>
           <v-card-title > <h1>{{this.saldo}}</h1></v-card-title>

          </v-card>

          

         


      </v-col>

    </v-row>


  <v-data-table
            :headers="headers_sms"
            :items="lista_premios"
            :items-per-page="12"
            :loading="loading_tab"
            class="elevation-1"
          >
  
     <template v-slot:item.pontuacao="{ item }">
           


            {{item.pontuacao}}

             <v-btn style="margin-left:10px;margin-left:200px"    small @click="premiar(item)"   color="primary"  >Resgatar Prêmio
                    <v-icon right dark>mdi-briefcase</v-icon>      
              </v-btn>
   
     </template>



  
  </v-data-table>
  </base-material-card>
  </v-container>
</template>
<script>
  import Promotor from '../../services/promotor'

  export default {
    name: 'Venda',

    mounted() {
           this.lista();
           //this.meu_saldo();
           this.totais();
          

    },

    data () {
      return {
         components: {
          DashboardCoreView: () => import('../dashboard/components/core/View'),
          },

        btn_load: false,
        loading_tab: false,
        alert_msg:false,
        saldo: 0,
        solicitado: 0,
        msg_dialog:'',
        controle_premiacao:{
          id_promotor: '',
          id_classificacao_pontos: '',
          token: '',
          evento: '',
        },
        lista_premios:[],
         headers_sms: [
          { text: 'Premiação', value: 'evento' },
          { text: 'Pontos', value: 'pontuacao' },
          { text: 'Resgatar' },

        ],

      }
    },

   
    methods: {
     
     

        clear () {
          this.$refs.form.reset();
        },

     

      lista(){
               this.loading_tab = true
         Promotor.eventos_premiacao().then(response => {
                if(response.status = 200){
                  this.lista_premios = response.data;
                }
              }).catch(e => {
                if(e.response.status === 403){
                  alert('Usuário não tem permissão')
                }
              }).finally(() => {
                     this.loading_tab = false

            });
        },

        

          totais(){
                Promotor.totais_dash_loja().then(response => {
                if(response.status = 200){
                   this.saldo = response.data.saldo;
                   this.solicitado = response.data.tsoli;
                }
              }).catch(e => {
                 if(e.response.status === 401){
                  this.$store.dispatch('auth/logout');
                  this.$router.push('/login');
                  }
              }).finally(() => {
              });

          },
  
       premiar(item){

            this.controle_premiacao.id_classificacao_pontos = item.id;
            Promotor.premiar(this.controle_premiacao).then(response => {
              if(response.data.codigo){
                  this.alert_msg = true;
                  this.msg_dialog = response.data.mensagem;
                  this.clear();
              }
              }).catch(e => {
              if(e.status === 401){
                 this.loading = false
                   this.alert_msg = true;
                   this.btn_load = false;
                  this.msg_dialog = "Por Favor tente novamente mais tarde!";
              }
             }).finally(() => {
                this.loading = false
                 this.btn_load = false;
                this.totais();
              })
      },

     
    },
  }
</script>
